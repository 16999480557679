import React, { useLayoutEffect, useEffect, useRef, useState } from "react"
import { css } from "@emotion/react"
import Seo from "~components/Seo"
import { graphql, navigate } from "gatsby"
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import JumpLinks from "~components/blocks/JumpLinks"
import BrandCardLarge from "~components/blocks/BrandCardLarge"
import { paramCase } from "change-case"
import { useQueryParam, StringParam} from 'use-query-params'
import BrandSlide from '~components/blocks/BrandSlide'
import resolveLink from '~utils/resolveLink'

const BrandsPage = ( data ) => {

  const {mobile, tablet} = breakpoints

  const brandsPage = data?.data?.sanityBrandsPage
  const brandsPageContent = brandsPage?._rawContent
  const brands = data?.data?.allSanityBrand

  const [activeBrand, setActiveBrand] = useState(null)
  const [windowWidth, setWindowWidth] = useState(0)
  const [deepLink, setDeepLink] = useState(false)
  const [activeLink, setActiveLink] = useState(null)
  // const [initialLoad, setInitialLoad] = useState(true)

	const { featuredBrand } = data?.data

  const BrandDetailRef = useRef(null)

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', e => {
      setWindowWidth(window.innerWidth)
    })
    return window.removeEventListener('resize', e => {
      setWindowWidth(window.innerWidth)
    })
  }, [])

  const jumpLinkList = [
    ...brands?.edges.map(brand => ({
        title: brand?.node?.title,
        id: paramCase(brand?.node?.title),
    })),
  ]

  const setAndScroll = (selected, i) => {
    setActiveBrand(i)
    window.scrollTo({
      top: (BrandDetailRef?.current?.offsetTop - 106),
      behavior: 'smooth',
    }) 
  }

	const navigateToBrand = brand => {
		const url = resolveLink('brand', brand.node.slug.current)
		navigate(url)
	}

  const [brandParam, setBrandParam] = useQueryParam('brand', StringParam)
  const [deepLinkInit, setDeepLinkInit] = useState(false)
  const cardParentRef = useRef()

  // open brand from query string on load
  useEffect(() => {
    if(windowWidth && !deepLink){
      let bs = brands?.edges?.map(edge => edge.node)
      let index = bs.findIndex(b => b.title === brandParam)
      if(index > -1 && windowWidth > 768){
        setAndScroll(null, index)
      } else if (index > -1 && windowWidth <= 768){
        window.setTimeout(() => {
          cardParentRef.current.children[index].scrollIntoView({
            behavior: 'smooth',
          })
        }, 1000)
      }
      setDeepLink(true)
      setDeepLinkInit(true)
    }
  }, [windowWidth])

  useEffect(() => {
    if(deepLinkInit){
      let bs = brands?.edges?.map(edge => edge.node)
      let index = bs.findIndex(b => b.title === brandParam)
      if(index > -1 && windowWidth > 768){
        setAndScroll(null, index)
      } else if (index > -1 && windowWidth <= 768){
        window.setTimeout(() => {
          cardParentRef.current.children[index].scrollIntoView({
            behavior: 'smooth',
          })
        }, 1000)
      }
    }
  }, [brandParam])

  // useEffect(() => {
  //   console.log(`activeBrand`, activeBrand)
  // }, [activeBrand])

  return (
    <div>
      <Seo
        title={featuredBrand?.title}
        image={featuredBrand?._rawContent?.seo?.socialImage?.asset?.url}
        description={featuredBrand?._rawContent?.seo?.metaDescription}
        metaTitle={featuredBrand?._rawContent?.seo?.metaTitle}
				url={resolveLink('brand', featuredBrand?.slug.current)}
      />
      <div css={css`
        position: sticky;
        top: 100vh;
        transform: translateY(calc(100% - 58px));
        white-space: nowrap;
        left: 40px;
        height: 0;
        width: 0;
        letter-spacing: 0.1em;
        ${mobile}{
          display: none;
        }
      `}>
        <div css={css`
          position: absolute;
          bottom: 0px;
        `}>
          <JumpLinks 
            links={jumpLinkList}
            onUpdate={activeLink => setActiveLink(activeLink)}
            currentLink={activeLink}
          />
        </div>
      </div>
      {/* <div ref={BrandDetailRef}>
        <BrandDetailSection  
          brands={brands?.edges}
          slideToShow={activeBrand}
          onClose={() => setActiveBrand(null)}
          css={css`
            margin-bottom: 108px;
            max-height: ${activeBrand || activeBrand === 0 ? '1000px' : '0'};
            transition: max-height 0.7s, ${activeBrand || activeBrand === 0 ? 'opacity 0.8s 0.5s' : 'opacity 0.5s'}, margin 0.3s;
            opacity: ${activeBrand || activeBrand === 0 ? '1' : '0'};
            overflow: hidden;
            ${mobile}{
              margin-bottom: ${activeBrand || activeBrand === 0 ? `108px` : `0px`};
            }
        `}/>
      </div> */}
			<Section>
				<BrandSlide brand={{node: featuredBrand}} css={css`
					grid-column: 4 / 10;
					margin: 50px 0 108px;
					${tablet}{
						grid-column: 4 / 13;
					}
					${mobile}{
						grid-column: span 12;
					}
				`} />
			</Section>
      <Section>
        <div ref={cardParentRef} css={css`
          grid-column: 4/13;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 20px;
          grid-row-gap: 176px;
          ${mobile}{
            grid-column: span 12;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 0px;
            grid-row-gap: 112px;
          }
          @media (max-width: 750px){
            grid-template-columns: 1fr;
          }
        `}>
          {brands?.edges.map((brand, i) => (
            <BrandCardLarge 
              onChange={selected => navigateToBrand(brand)}
              active={brand?.node?.id === activeBrand}
              title={brand?.node?.title} 
              image={brand?.node?._rawContent?.image?.asset?.id} 
              country={brand?.node?._rawContent?.country} 
              shortText={brand?.node?._rawContent?.shortText} 
              link={brand?.node?.slug?.current}
              id={paramCase(brand?.node?.title)}
              key={brand?.node?.id}
              noLink
              css={css`
                grid-column: span 1;
              `}
            />
          ))}
        </div>
      </Section>
      <div css={css`height: 58px; margin-bottom: -58px;`} />
    </div>
  )
}

export const query = graphql`
  query brandQuery($slug: String){
		featuredBrand: sanityBrand(slug: {current: {eq: $slug}}){
			_rawContent(resolveReferences: {maxDepth: 10})
			title
			slug {
				current
			}
			id
		}
    allSanityBrand(
			sort: {order: ASC, fields: slug___current}
			filter: {slug: {current: {ne: $slug}}}
			) {
      edges {
        node {
          _rawContent(resolveReferences: {maxDepth: 10})
          title
          slug {
            current
          }
          id
        }
      }
    }
  }
`

export default BrandsPage
